/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import '../../sass/main.sass'

function SEO({ 
	description, 
	lang, 
	meta, 
	title,
	image,
	noIndex
}) {
	const { site } = useStaticQuery(
		graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
	)

	const metaDescription = description || site.siteMetadata.description

	const titleTemplate = (title || '').includes('Your Creative') ? '%s' : `%s | ${site.siteMetadata.title}`

	const _meta = [
		{
			name: 'viewport',
			content: 'width=device-width',
		},
		{
			name: `description`,
			content: metaDescription,
		},
		{
			property: `og:title`,
			content: title,
		},
		{
			property: `og:description`,
			content: metaDescription,
		},
		{
			property: `og:type`,
			content: `website`,
		},
		{
			name: `twitter:card`,
			content: `summary`,
		},
		{
			name: `twitter:creator`,
			content: site.siteMetadata.author,
		},
		{
			name: `twitter:title`,
			content: title,
		},
		{
			name: `twitter:description`,
			content: metaDescription,
		},{
			name: 'og:image',
			content: image || undefined
		}
	].concat(meta)

	if(noIndex){
		_meta.push({
			name: 'robots',
			content: 'noindex, nofollow'
		})
	}

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={titleTemplate}
			meta={_meta}
		/>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

export default SEO
